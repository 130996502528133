import React, { FC, useContext, useState } from 'react'
import { Box, Button, Chip, Pagination, Tooltip } from '@mui/material'
import { styled } from '@mui/system'
import ModalUnstyled from '@mui/base/ModalUnstyled'
import ListUser from './ListUser'
import { useDispatch, useSelector } from 'react-redux'
import { getParticipants, setParticipants, nullParticipants } from '../../../../redux/reducers/drills_reducer'
import { AppStateType } from '../../../../redux/store'
import { LanguageContext, translate } from '../../../../constants/lang'
import { Done } from '@mui/icons-material'
import ListParticipants from './ListParticipants'
import QuestionMark from '../../../../assets/icon/drill/questionMark'
import { UsersType } from '../../../../types/types'
import { debounce } from '../../../../utils/debounce'
import { getAllUsers } from '../../../../redux/reducers/groups_reducer'

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 10;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Backdrop = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`

const style = {
  box: {
    width: 792,
    maxHeight: '100%',
    bgcolor: 'white',
    border: '0px solid #000',
    borderRadius: 6,
    overflow: 'auto',
  },
  info: {
    padding: '24px 44px 12px 44px',
    overflow: 'auto',
    maxHeight: 717,
  },
  title: {
    fontWeight: 800,
    fontSize: 49,
    mb: 2,
  },
  icon: {
    display: 'inline-flex',
    verticalAlign: 'middle',
    padding: '5px 3px 5px 7px',
    overflow: 'auto',
  },
  titleChild: {
    fontWeight: 800,
    fontSize: 20,
    mb: 2,
  },
  chipActive: {
    backgroundColor: '#E0F2FF',
    ml: 2,
    borderRadius: 2,
    color: '#4E4B66',
  },
  chipPassive: { ml: 2, borderRadius: 2, color: '#4E4B66' },
  padding: {
    m: 4,
  },
  drill: {
    fontWeight: 800,
    fontSize: 20,
  },
}

export const EditParticipants: FC<any> = (props) => {
  const dispatch = useDispatch()
  interface checketUsers {
    login: string
    roleId: string
    leader: boolean
  }
  const [switchUsers, setSwitchUsers] = React.useState<boolean>(false)
  const [checkedUsers, setCheckedUsers] = useState<string[]>([])
  const [checkedParticipants, setCheckedParticipants] = useState<UsersType[]>([])
  const [checkedUsersRole, setCheckedUsersRole] = useState<checketUsers[]>([])
  const { language } = useContext(LanguageContext)
  const [page, setPage] = useState(1)
  const userslist = useSelector((store: AppStateType) => store.Groups.allUsers)
  const displayedUsers = switchUsers ? checkedParticipants : userslist

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  React.useEffect(() => {
    if (!props.isOpenEditParticipants) return
    dispatch(getParticipants(props.drillId, 1, ''))
  }, [props.isOpenEditParticipants])
  const selectParticipants = useSelector((store: AppStateType) => store.Drills.participantsSelectTeam)
  const totalUsers = useSelector((store: AppStateType) => store.Groups.totalUser)
  const sizeUsersPage = useSelector((store: AppStateType) => store.Groups.sizeUsersPage)
  const latestPage = Math.ceil(totalUsers / sizeUsersPage)
  React.useEffect(() => {
    if (!props.isOpenEditParticipants) return
    let arrParticipants = selectParticipants.map((item: any) => {
      item.roleId = item.id
      item.isChecked = true
      item.length > 0 ? (item.isLeader = true) : (item.isLeader = false)
      return item
    })
    let arrParticipantsRole = selectParticipants.map((item: any) => {
      return {
        login: item.login,
        roleId: item.socialRole.id,
        leader: item.leader,
      }
    })
    let arrUsers = selectParticipants.map((item: any) => item.login)
    setCheckedParticipants(arrParticipants)
    setCheckedUsers(arrUsers)
    setCheckedUsersRole((prevCheckedUsersRole) => {
      // Объединяем старые и новые данные, обновляя записи по login
      const updatedUsersRole = prevCheckedUsersRole.map((existingUser) => {
        const newUser = arrParticipantsRole.find((user: any) => user.login === existingUser.login)
        return newUser || existingUser // Если есть новый элемент с тем же login, обновляем, иначе оставляем старый
      })
      // Добавляем новые элементы, которых еще нет в предыдущем массиве
      const newUsers = arrParticipantsRole.filter(
        (newUser: any) => !prevCheckedUsersRole.some((user) => user.login === newUser.login),
      )
      return [...updatedUsersRole, ...newUsers]
    })
  }, [selectParticipants, props.isOpenEditParticipants])

  const setNewParticipants = () => {
    dispatch(setParticipants(checkedUsersRole, props.drillId))
    dispatch(nullParticipants(props.drillId))
    props.closeEditParticipants()
  }

  const searchUsers = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(1)
    debouncedSearchUsers(event.target.value)
  }

  const debouncedSearchUsers = debounce((text: string) => {
    dispatch(getAllUsers(1, text))
    dispatch(getParticipants(props.drillId, 1, text))
  }, 300)

  const tooltipText = (
    <>
      <Box sx={{ mb: 1 }}>
        <b>{translate('all', language)}</b> - {translate('allUsersAdded', language)}
      </Box>
      <Box>
        <b>{translate('participants_v1', language)}</b> - {translate('participantsDescription', language)}
      </Box>
    </>
  )

  return (
    <>
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={props.isOpenEditParticipants}
        onClose={props.closeEditParticipants}
        BackdropComponent={Backdrop}
      >
        <Box sx={style.box}>
          <Box sx={style.info}>
            <Box sx={style.title}>{translate('editParticipants', language)}</Box>
            <Box sx={style.titleChild}>
              {translate('searchAmong', language)}
              <Tooltip title={tooltipText}>
                <Box sx={style.icon}>
                  <QuestionMark />
                </Box>
              </Tooltip>
              <Chip
                label={translate('amongAll', language)}
                onClick={() => {
                  if (switchUsers) {
                    setSwitchUsers((prev) => !prev)
                  }
                }}
                variant={!switchUsers ? 'filled' : 'outlined'}
                icon={!switchUsers ? <Done /> : <></>}
                sx={!switchUsers ? style.chipActive : style.chipPassive}
              />
              <Chip
                label={translate('participants', language)}
                onClick={() => {
                  if (!switchUsers) {
                    setSwitchUsers((prev) => !prev)
                  }
                }}
                variant={switchUsers ? 'filled' : 'outlined'}
                icon={switchUsers ? <Done /> : <></>}
                sx={switchUsers ? style.chipActive : style.chipPassive}
              />
            </Box>
            <ListUser
              userslist={displayedUsers}
              checkedUsers={checkedUsers}
              setCheckedUsers={setCheckedUsers}
              checkedParticipants={checkedParticipants}
              checkedUsersRole={checkedUsersRole}
              setCheckedUsersRole={setCheckedUsersRole}
              drillId={props.drillId}
              page={page}
              setPage={setPage}
              searchUsers={searchUsers}
            />
          </Box>
          {!switchUsers && (
            <Pagination onChange={handleChange} sx={style.padding} page={page} count={latestPage} color="primary" />
          )}
          <Box sx={style.padding}>
            <Button onClick={setNewParticipants} variant="contained" size="large" sx={{ mr: 2, borderRadius: '14px' }}>
              {translate('labelSave', language)}
            </Button>
            <Button onClick={props.closeEditParticipants} size="large" sx={{ mr: 2, borderRadius: '14px' }}>
              {translate('labelCancel', language)}
            </Button>
          </Box>
        </Box>
      </StyledModal>
    </>
  )
}
