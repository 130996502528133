// Типы чатов
export const CHAT_TYPE_SOCIAL_NETWORK = 'ru.digitalatom.sonet.shared.NETWORK' // социальные сети
export const CHAT_TYPE_AUTHORITY = 'ru.digitalatom.sonet.shared.AUTHORITY' // пресс-служба
export const CHAT_TYPE_PRESS = 'ru.digitalatom.sonet.shared.PRESS' // СМИ

// Состояние сообщения в тренировке с согласованием
export const MESSAGE_STATE_PENDING = 'ru.digitalatom.sonet.shared.roles.PENDINGTYPE'
export const MESSAGE_STATE_AGREED = 'ru.digitalatom.sonet.shared.roles.AGREEDTYPE'
export const MESSAGE_STATE_DENIED = 'ru.digitalatom.sonet.shared.roles.DENIEDTYPE'

//комнаты room
export const ROOM_GENERAL = 'GENERAL'
export const ROOM_PRESS = 'PRESS'
export const ROOM_AUTHORITY = 'AUTHORITY'
export const ROOM_NETWORK = 'NETWORK'

export const MESSAGE_TYPE_IS_BLOCKS = 'BLOCKS'
export const MESSAGE_TYPE_MESSAGEv4 = 'ru.digitalatom.sonet.shared.Protocol.ChatMessageV4'
export const MESSAGE_TYPE_DELETED = 'MSG_DELETED'
export const MESSAGE_TYPE_EMOS_FETCHGRADES = `ru.digitalatom.sonet.shared.Protocol.FetchGrades`
export const MESSAGE_TYPE_JOINED = 'PROTOCOL_JOINED'
export const MESSAGE_TYPE_QUIT = 'PROTOCOL_QUIT'
