import { Emos, emosType, ReactionCount } from '../types/types'

export function countLikes(data: emosType[], myId: string) {
  const result: Emos = {}

  data.forEach((item) => {
    const { messageId, emo, userId } = item

    if (!result[messageId]) {
      result[messageId] = {
        Like: { count: 0, users: [], isMyReaction: false },
        Dislike: { count: 0, users: [], isMyReaction: false },
      }
    }

    if (emo === '👍') {
      const likeData = result[messageId].Like
      const isUserInLikes = likeData.users.includes(userId)

      if (isUserInLikes) {
        // Убираем лайк, если он уже был поставлен этим пользователем
        likeData.count = Math.max(0, likeData.count - 1)
        likeData.users = likeData.users.filter((user) => user !== userId)
        if (userId === myId) likeData.isMyReaction = false
      } else {
        // Добавляем лайк, если его не было
        likeData.count += 1
        likeData.users.push(userId)
        if (userId === myId) likeData.isMyReaction = true
      }
    } else if (emo === '👎') {
      const dislikeData = result[messageId].Dislike
      const isUserInDislikes = dislikeData.users.includes(userId)

      if (isUserInDislikes) {
        // Убираем дизлайк, если он уже был поставлен этим пользователем
        dislikeData.count = Math.max(0, dislikeData.count - 1)
        dislikeData.users = dislikeData.users.filter((user) => user !== userId)
        if (userId === myId) dislikeData.isMyReaction = false
      } else {
        // Добавляем дизлайк, если его не было
        dislikeData.count += 1
        dislikeData.users.push(userId)
        if (userId === myId) dislikeData.isMyReaction = true
      }
    }
  })
  console.log('пересчет', result)
  return result
}
